import React, { useCallback, useEffect, useState } from "react";
import SectionTitle from "../../components/sectionTitle";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Form,ProgressBar } from "react-bootstrap";
import CustomDropDown from "../../components/DropDown/CustomDropDown";
import styles from "./quadrantView.module.css";
import { Scatter } from "react-chartjs-2";
import {
    Chart,
    LinearScale,
    CategoryScale,
    PointElement,
    Tooltip,
    LineElement,
    Filler,
    BarElement,
    Legend,
    LineController,
    BarController,
  } from "chart.js";
import { createQuadrants,generateSummaryData,getAllQuadrantFilters,metricsMapper,validateInputData,generateContent, createImage ,initialFilterConfigs } from "../../helper/Quadrant/quadrantHelper";
import Annotation from "chartjs-plugin-annotation";
import cubejs from "@cubejs-client/core";
import { useGlobalContext } from "../../context/global";
import { formatDate } from "../../components/Reports/Components/utils";
import {toast} from 'react-toastify'
import useToken from "../../helper/Reports/useToken";
import topPerformingImage from '../../assets/svgs/202.svg';
import lowDiscoveryImage from '../../assets/svgs/questionmark.svg';
import nonPerformingImage from '../../assets/svgs/nonperforming.svg';
import highPotentialImage from '../../assets/svgs/topstar.svg';
import FiltersModal from "../../components/modal/FiltersModal";
import MultiSelect from "../../components/DropDown/Multiselect";
import OptimizerFilters from "../../components/optimizer/OptimizerFilters";
import downArrowImage from '../../assets/svgs/downArrow.svg';
  import './quadrantview.css';
import PageStatus from "../../components/Common/PageStatus";
import { useNavigate } from "react-router-dom";
import { masterApi } from "../../apis/masterApi";
import MasterTagetinngActionAlertMModal from "../../components/modal/masterTargetinngActionnAlertMModal/masterTargetingActionnAlertModal";
import { campaignListActions } from "../../redux/slices/campaignList";


const QuadrantView = () => {
  const dispatch = useDispatch();
  const dashboardSideBarVisibiltyState = useSelector(
    (state) => state.dashboardData.dashboardSideBarVisibiltyState
  );
  const quadrants = createQuadrants();
  const {cubeToken,setCubeToken,calendarDate,clientAccountId} = useGlobalContext()
  const navigate = useNavigate();

  const [conditionalFilters,setConditionalFilters] = useState([{id:1,filterName:'',value:''},{id:2,filterName:'',value:''}])
  
  const [modalVisible,setModalVisible] = useState(false)
  const [selectedAdditionalMetrics, setSelectedAdditionalMetrics] = useState([]);
  const [q4Asins,setQ4Asins] = useState([])
  const [showAlertMsgOf,setShowAlertMsgOf] = useState("")

  const [cubeQueryMeasure, setCubeQueryMeasure] = useState([
    "VariantAnalysisTable.cost",
    "VariantAnalysisTable.costDRR",
    "VariantAnalysisTable.impressions",
    "VariantAnalysisTable.impressionsDRR",
    "VariantAnalysisTable.clicks",
    "VariantAnalysisTable.clicksDRR",
    "VariantAnalysisTable.ctr",
    "VariantAnalysisTable.ctrDRR",
    "VariantAnalysisTable.cpc",
    "VariantAnalysisTable.cpcDRR",
    "VariantAnalysisTable.cpm",
    "VariantAnalysisTable.cpmDRR",
    "VariantAnalysisTable.conversions14d",
    "VariantAnalysisTable.conversions14dDRR",
    "VariantAnalysisTable.conversion_rate",
    "VariantAnalysisTable.conversion_rateDRR",
    "VariantAnalysisTable.sales14d",
    "VariantAnalysisTable.sales14dDRR",
    "VariantAnalysisTable.average_order_value",
    "VariantAnalysisTable.average_order_valueDRR",
    "VariantAnalysisTable.acos",
    "VariantAnalysisTable.acosDRR",
    "VariantAnalysisTable.roas",
    "VariantAnalysisTable.roasDRR",
  ]);

  
  const yAxisStartValue = Number(conditionalFilters[1].value ?? 0);
  const xAxisStartValue = Number(conditionalFilters[0].value ?? 0);
  const [filters,setFilters] = useState(initialFilterConfigs)


  const paramsForQuadrantFilters = {
    xFilterName:conditionalFilters[0]?.filterName,
    xFiltervalue:xAxisStartValue,
    yFilterName:conditionalFilters[1]?.filterName,
    yFilterValue:yAxisStartValue,
  }

  const {quadrant1Filter,quadrant2Filter,quadrant3Filter,quadrant4Filter} = getAllQuadrantFilters(paramsForQuadrantFilters);
  const wrapperWidth = window.innerWidth > 1200 ? '800px' : window.innerWidth > 1000 ? '800px' : '100%';

  const getAdditionalFilters = (data) => {
    let filters = []
     data.forEach((filter) => {
      if(filter?.default?.length > 0) {
        filters.push({member:`VariantAnalysisTable.${filter?.memberkey}`,operator:'equals',values:filter?.default})
      }
    });
    return filters
  }

  const additonalFilters = getAdditionalFilters(filters)


  Chart.register(CategoryScale, LinearScale, PointElement, quadrants, Tooltip , LineElement , Filler , Annotation , BarElement , Legend , BarController );

  const YAxisMetrics = ["ROAS", "Orders", "Revenue", "Conv. Rate","Spends", "Clicks", "Impressions","CTR","CPM","ACOS","CPC",'AOV'];

  const XAxisMetrics = ["ROAS", "Orders", "Revenue", "Conv. Rate","Spends", "Clicks", "Impressions","CTR","CPM","ACOS","CPC",'AOV'];

  const additionalMetrics = ["ROAS", "Orders", "Revenue", "Conv. Rate","Spends", "Clicks", "Impressions","CTR","CPM","ACOS","CPC",'AOV'];

  const cubejsApi = cubejs(cubeToken, {
    apiUrl: process.env.REACT_APP_CUBE_JS_URL,
  });

  const [additionalMetric,setAdditionalMetric] = useState("CTR") 
  const [loading,setIsLoading] = useState(false);
  const [completion,setCompletion] = useState(0)

  const [cubeQueryOrder, setCubeQueryOrder] = useState([
    ["VariantAnalysisTable.cost", "desc"],
    ["VariantAnalysisTable.parentSku", "desc"],
  ]); 

  


  // All Summary Related states
  const [q1Summary,setQ1Summary] = useState({summary:{},data:[]});
  const [q2Summary,setQ2Summary] = useState({summary:{},data:[]});
  const [q3Summary,setQ3Summary] = useState({summary:{},data:[]});
  const [q4Summary,setQ4Summary] = useState({summary:{},data:[]});

  const [token, refresh] = useToken("");


  const [fetchIssue,setFetchIssue] = useState(false)
  const yAxisFilter = conditionalFilters[1]?.filterName
  const xAxisFilter = conditionalFilters[0]?.filterName
  const yAxisMetricSelected =
    yAxisFilter === "" ? "Select" : yAxisFilter;
  const xAxisMetricSelected =
    xAxisFilter === "" ? "Select" : xAxisFilter;

  const xKeyFilter = metricsMapper[xAxisMetricSelected]
  const yKeyFilter = metricsMapper[yAxisMetricSelected]


  const additionalFilterMetric1 = selectedAdditionalMetrics?.map((data) => { return {label:data,value:q1Summary?.summary[metricsMapper[data]] ?? 0}});
  const additionalFilterMetric2 = selectedAdditionalMetrics?.map((data) => { return {label:data,value:q2Summary?.summary[metricsMapper[data]] ?? 0}});
  const additionalFilterMetric3 = selectedAdditionalMetrics?.map((data) => { return {label:data,value:q3Summary?.summary[metricsMapper[data]] ?? 0}});
  const additionalFilterMetric4 = selectedAdditionalMetrics?.map((data) => { return {label:data,value:q4Summary?.summary[metricsMapper[data]] ?? 0}});

 
  
  useEffect(() => {
  if(validateInputData(conditionalFilters)){
      handleQuadrantDataUpdate()
    }
  },[filters])


  const quadrant1MetricX = q1Summary?.summary[xKeyFilter] ?? 0
  const quadrant1MetricY = q1Summary?.summary[yKeyFilter] ?? 0
  const quadrant2MetricX = q2Summary?.summary[xKeyFilter] ?? 0
  const quadrant2MetricY = q2Summary?.summary[yKeyFilter] ?? 0
  const quadrant3MetricX = q3Summary?.summary[xKeyFilter] ?? 0
  const quadrant3MetricY = q3Summary?.summary[yKeyFilter] ?? 0
  const quadrant4MetricX = q4Summary?.summary[xKeyFilter] ?? 0
  const quadrant4MetricY = q4Summary?.summary[yKeyFilter] ?? 0

  

  const  q1Content = generateContent(q1Summary?.summary['total_count'],xAxisFilter,quadrant1MetricX,yAxisFilter,quadrant1MetricY,additionalFilterMetric1)
  const  q2Content = generateContent(q2Summary?.summary['total_count'],xAxisFilter,quadrant2MetricX,yAxisFilter,quadrant2MetricY,additionalFilterMetric2)
  const  q3Content = generateContent(q3Summary?.summary['total_count'],xAxisFilter,quadrant3MetricX,yAxisFilter,quadrant3MetricY,additionalFilterMetric3)
  const  q4Content = generateContent(q4Summary?.summary['total_count'],xAxisFilter,quadrant4MetricX,yAxisFilter,quadrant4MetricY,additionalFilterMetric4)

  const highPotential = createImage(highPotentialImage);
  const lowDiscovery = createImage(lowDiscoveryImage);
  const nonperforming = createImage(nonPerformingImage);
  const topperforming = createImage(topPerformingImage);
  

  const timeDimensions =   [{dimension:"VariantAnalysisTable.obDate",dateRange: [
        formatDate(calendarDate[0].startDate),
        formatDate(calendarDate[0].endDate),
      ]}]

  const getQuadrantData = (queryFilter,updateCompletion) => {
    return new Promise((resolve,reject) => {

      const excludeSBCampaignsFiltering = {"member":"VariantAnalysisTable.campaignFormat","operator":"notEquals","values":["SB","SB(V)"]}

      const filtersData = [
        {
          and:[{ member: "VariantAnalysisTable.profileId",
        operator: "equals",
        values: [clientAccountId]},...additonalFilters,excludeSBCampaignsFiltering]
      },
        {
          and:queryFilter
        }
      ]
      cubejsApi
        .load(
          {
            measures: cubeQueryMeasure,
            order: cubeQueryOrder,
            dimensions: ['VariantAnalysisTable.asin'],
            filters: filtersData,
            timeDimensions: timeDimensions,
            limit: 50000,
          },
          {
            headers: {
              "Access-Control-Allow-Origin": true,
            },
          }
        )
        .then((resultSet) => {
         resolve(resultSet?.tablePivot({}))
         updateCompletion()
        })
        .catch((error) => {
          reject(error)
          if (error.message === "Invalid token") {
            setCubeToken(false);
            localStorage.setItem("cubeToken", null);
            refresh();
          }
        });
    })
  }

  const fetchAllQuadrantData = async () => {
    setCompletion(0);
    let completion = 0
    // This function will be used to update the completion status as we're hitting all the API's parallely and not waiting for one to complete
    const updateCompletion = () => {
      completion += 25;
      setCompletion(completion);
    };
    try {  
      const promises = [
        getQuadrantData(quadrant1Filter,updateCompletion),
        getQuadrantData(quadrant2Filter,updateCompletion),
        getQuadrantData(quadrant3Filter,updateCompletion),
        getQuadrantData(quadrant4Filter,updateCompletion),
      ];
      // Hitting all the API's parallely for high performance as done in D2C not waiting for one to complete
      const [q1Data, q2Data, q3Data, q4Data] = await Promise.all(promises);
      setQ4Asins(q4Data.map(data => data['VariantAnalysisTable.asin']));
      const q1Summary = generateSummaryData(q1Data);
  
      const q2Summary = generateSummaryData(q2Data);
  
      const q3Summary = generateSummaryData(q3Data);
  
      const q4Summary = generateSummaryData(q4Data);
      setCompletion(100);
      // Now you have all the summary data
      return { q1Summary, q2Summary, q3Summary, q4Summary };
    } catch (error) {
      toast.error('Some unknown error occurred!Please Retry.')
      console.error("Error fetching quadrant data:", error);
    }
  };

  const handleQuadrantDataUpdate = async  (e) => {
    try {
      if(validateInputData(conditionalFilters)) {
      setIsLoading(true)
      const {q1Summary,q2Summary,q3Summary,q4Summary} = await fetchAllQuadrantData()
       setQ1Summary({summary:q1Summary})
       setQ2Summary({summary:q2Summary});
       setQ3Summary({summary:q3Summary});
       setQ4Summary({summary:q4Summary})
       setIsLoading(false)
       setFetchIssue(false)
      } else {
        toast.error('Please select both axis metrics and values to generate quadrant view')
      }
    } catch (error) {
      setFetchIssue(true)
      setIsLoading(false)
      toast.error(JSON.stringify(error))
      if (error.message === "Invalid token") {
        setCubeToken(false);
        localStorage.setItem("cubeToken", null);
        refresh();
      }
    }
   
  }

  function convertToUrlFormat(data) {
    const urlParams = data?.map(item => {
        const key = item.member.split('.').pop(); // Get the last part of the member name
        const value = item.values.join(','); // Join multiple values with a comma
        return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`; // Encode the key and value for URL
    });
    
    return urlParams.join('&'); // Join all parameters with '&'
}


  const handleProductView = (quadrant) => {
    let filters = ''
    let queryFilter = [];
    if(quadrant === 1) {
      queryFilter = quadrant1Filter
    } else if(quadrant === 2) {
      queryFilter = quadrant2Filter
    } else if(quadrant === 3) {
      queryFilter = quadrant3Filter
    } else if(quadrant === 4){
      queryFilter = quadrant4Filter
    }
   
    filters = encodeURIComponent(JSON.stringify(queryFilter))
    const urlFormattedString = convertToUrlFormat(additonalFilters)
    if(filters !== '') {
      window.open(
        '/product-analytics?reportType=Product&viewtype=Custom+View&' +
        urlFormattedString +"&" +
        'urlQuery=' + filters ,
        "_blank"
      );
    }
  }

  const pauseAdsClick = () => {
    dispatch(campaignListActions.showActionAlertModal());
    setShowAlertMsgOf("Are you sure you want to pause ads for the products in this quadrant?")
  }
  const noClickHandler = () => {
    dispatch(campaignListActions.hideActionAlertModal());
  }
  const handleConfirmPause =async () => {
    try {
      const status = "paused";
      const data = {
        status: status,
        asins: q4Asins,
      };
      dispatch(campaignListActions.hideActionAlertModal());
      const result = await masterApi(data, "uploadasinsstatus", false, "post");

      if (result?.statusCode !== 200) {
        toast.error('Some Unknown Error Occurred')
      } else {
        toast.success('Updated Successfully changes might take some minutes to reflect!')
      }
    } catch (error) {
      throw error.message;
    }
  }

  const handleProductAction = (action) => {
    if(action === 'PAUSE') {
      pauseAdsClick();
    }
    if(action === "CREATE_PRODUCT_SET") {
      return navigate("/create-product-set")
    }
  }

  
  const handleConditionChange = (actionkey, receivedValue, recievedId) => {
    setConditionalFilters((prev) => {
      return [...prev]?.map((prevFilter) => {
        if (prevFilter.id === recievedId) {
          if(actionkey === 'filterName') { 
            prevFilter[actionkey] = receivedValue;
          }
          if(actionkey === 'value' && !isNaN(receivedValue)) {
            prevFilter[actionkey] = receivedValue;
          }
        }

        return { ...prevFilter };
      });
    });
};

const isDataPresent = () => {
  return Object?.keys(q1Summary?.summary).length > 0 && Object?.keys(q2Summary?.summary).length > 0 && Object?.keys(q3Summary?.summary).length > 0 && Object?.keys(q4Summary?.summary).length > 0
}
const getAppliedFilters = (e,filterKeys,type) => {
  setFilters((prev) => {
    return [...prev]?.map((prevFilter) => {
      if(type === prevFilter?.key) {
        prevFilter.default = e;
      }
      return {...prevFilter}
    })
  })
}


const isAllQuadrantDataPresent = isDataPresent()



  return (
    <div
      className={
        dashboardSideBarVisibiltyState
          ? "main-content-container-onopen-mode"
          : "main-content-container-oncolapse-mode"
      }
      id="main-box"
    >
     
      <div className="d-flex justify-content-between align-items-center mb-1">
        <div className="mt-1">
      <SectionTitle sectionTitleHeading={"Quadrant View"}></SectionTitle>
      </div>
      </div>
      <OptimizerFilters handleDropdown={getAppliedFilters} filtersData={filters}/>
     


      <Card>
        <Card.Body>
          
          <div className="d-flex justify-content-between">
            <div className="d-flex gap-5">
            <div className="d-flex align-items-center gap-3">
                <CustomDropDown
                  defaultSelected={[]}
                  targettingValue={xAxisMetricSelected}
                  data={XAxisMetrics}
                  title={"Select X Axis Metric"}
                  filterCampaigns={(type, value) => {
                    handleConditionChange('filterName',type?.target?.value,conditionalFilters[0]?.id)
                  }}
                />
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label className="filter-options m-0">
                    X Value
                  </Form.Label>
                  <Form.Control
                    size="lg"
                    type="text"
                    onChange={(e) => {
                      handleConditionChange('value',e?.target?.value?.trim(),conditionalFilters[0]?.id)
                    }}
                    value={conditionalFilters[0]?.value}
                    placeholder="Enter Value"
                  />
                </Form.Group>
              </div>
              <div className="d-flex align-items-center gap-3">
                <CustomDropDown
                  defaultSelected={[]}
                  data={YAxisMetrics}
                  targettingValue={yAxisMetricSelected}
                  title={"Select Y Axis Metric"}
                  filterCampaigns={(type, value) => {
                    handleConditionChange('filterName',type?.target?.value,conditionalFilters[1]?.id)
                  }}
                />
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label className="filter-options m-0">
                    Y Value
                  </Form.Label>
                  <Form.Control
                    size="lg"
                    type="text"
                    onChange={(e) => {
                        handleConditionChange('value',e?.target?.value?.trim(),conditionalFilters[1]?.id)
                    }}
                    value={conditionalFilters[1]?.value}
                    placeholder="Enter Value"
                  />
                </Form.Group>
              </div>
            </div>
            <div className="d-flex align-items-center mt-3">
              <button className={styles.cardPrimaryBtn} disabled={loading}  onClick={handleQuadrantDataUpdate}>
               { loading ? 'Generating...' : 'Generate Quadrant'}
              </button>
            </div>
          </div>
        </Card.Body>
      </Card>
      <p className="quadrant-view-vs-heading mt-3 mb-2">{yAxisMetricSelected !== "Select" &&
            xAxisMetricSelected !== "Select" ? ` ${xAxisMetricSelected} V/S ${yAxisMetricSelected}  Performance` : null} </p>
      <Card className="mt-0">
      
        <Card.Body>
          <div className="d-flex justify-content-center">
          <MultiSelect
                      title={"Select Additional Metrics"}
                      defaultSelected={selectedAdditionalMetrics}
                      options={additionalMetrics.filter((data) => data !== yAxisMetricSelected && data !== xAxisMetricSelected)}
                      onApply={(e) => {
                        if(e.length > 3) {
                          toast.error('You can select maximum 3 additional metrics')
                          return
                        }
                        setSelectedAdditionalMetrics(e);
                      }}
                      isShowSelectAll={false}
                      isSearch={false}
                      placeHolder={"Select additional metrics"}
                      externalDivClasses={styles.additionalMetrics}
                    />
          </div>
          <div style={{minHeight:'500px'}} className="d-flex justify-content-center align-items-center">
          {
            loading 
            ? <>
            <div>
                 <div className="text-center" style={{fontSize:'20px',letterSpacing:'1'}}>
                        {completion <= 10 ? 'Fetching Data...' : completion === 25 ? 'Processing data...': completion === 50 ? `Generating Quadrant`  : completion === 75 ? 'Almost there...' : ''}
                      </div>
                      <div style={{display:"flex",gap:'12px'}}>
                          <div style={{width:'150px'}}> <ProgressBar variant={'info'} size={'medium'} now={completion >= 25 ? 100 : 0} /></div>
                          <div style={{width:'150px'}}> <ProgressBar variant={'info'} size={'medium'} now={completion >= 50 ? 100 : 0} /></div>
                          <div style={{width:'150px'}}> <ProgressBar variant={'info'} size={'medium'} now={completion >= 75 ? 100 : 0} /></div>
                          <div style={{width:'150px'}}> <ProgressBar variant={'info'} size={'medium'} now={completion > 75 ? 100 : 0} /></div>
                      </div>
            </div>
            </> : (isAllQuadrantDataPresent && !loading) ? <>
            <div style={{ height: "600px", paddingBottom: "40px",marginTop:'24px',display:'flex',justifyContent:'center' }}>
            <div style={{width: wrapperWidth,position:'relative'}}>
                    <div className={`${styles.axis} ${styles.left}`}>
                        <div style={{width:'200px'}}></div>
                          <div>
                            {yAxisMetricSelected}
                            {/* <div>{ headings.filter(head => head?.sortKey === conditionalFilters[1].filterName).map((data) =>  stripHtml(data?.name)) }  </div> */}
                            <div style={{paddingLeft:'30px'}}>{conditionalFilters[1].value}</div>
                          </div>
                          <div style={{transform: 'rotate(90deg)',width: '0px'}}>y</div>
                        </div>
                        <div className={`${styles.axis} ${styles.bottom}`}>
                          <div></div>
                          <div style={{textAlign:'center'}}>   
                            <div >{conditionalFilters[0].value}</div>
                            {xAxisMetricSelected}
                            {/* <div >{ headings.filter(head => head?.sortKey === conditionalFilters[0].filterName).map((data) =>  stripHtml(data?.name)) } </div> */}
                          </div>
                          <div style={{textAlign:'right'}}>x</div>
                        </div>
                    <div className={styles.quadrantWrapper}>
                        <div className={`${styles.quadrant} ${styles.high}`}>
                            <div className={styles.headingWrapperLeft}>
                                <div className={styles.headingImage}><img src={highPotential.src} alt=''/></div>
                                <div className={styles.heading}> High Potential</div>
                            </div>
                            {q2Content.map((item, index) => (
                              <p className={styles.subText} key={index}>{item}</p>
                            ))}
                            <div className={styles.cardBtnContainer}>
                            <div onClick={(e)=>handleProductView(2)} className={styles.cardPrimaryBtn}>{q2Summary?.summary['total_count']} Products</div>
                            <div onClick={(e)=>handleProductAction("CREATE_PRODUCT_SET")} className={styles.cardPrimaryBtn}>Create New Product Set</div>
                            </div>
                        </div>
                        <div className={`${styles.quadrant} ${styles.top}`}>
                            <div className={styles.headingWrapperRight}>
                                <div className={styles.heading}>Top Performing</div>
                                <div className={styles.headingImage}><img src={topperforming.src} alt=''/></div>
                            </div>
                            {q1Content.map((item, index) => (
                              <p className={styles.subText} key={index}>{item}</p>
                            ))}
                            <div className={styles.cardBtnContainer}>
                            <div  onClick={(e)=>handleProductView(1)} className={styles.cardPrimaryBtn}>{q1Summary?.summary['total_count']} Products</div>
                            <div onClick={(e)=>handleProductAction("CREATE_PRODUCT_SET")} className={styles.cardPrimaryBtn}>Create New Product Set</div>
                            </div>
                        </div>
                        <div className={`${styles.quadrant} ${styles.low}`}>
                          <div className={styles.headingWrapperLeft}>
                                <div className={styles.headingImage}><img src={lowDiscovery.src} alt=''/></div>
                                <div className={styles.heading}>Low Discovery</div>
                            </div>
                            {q3Content.map((item, index) => (
                              <p className={styles.subText} key={index}>{item}</p>
                            ))}
                            <div className={styles.cardBtnContainer}>
                            <div onClick={(e)=>handleProductView(3)} className={styles.cardPrimaryBtn}>{q3Summary?.summary['total_count']} Products</div>
                            <div onClick={(e)=>handleProductAction("CREATE_PRODUCT_SET")} className={styles.cardPrimaryBtn}>Create New Product Set</div>
                            </div>
                        </div>
                        <div className={`${styles.quadrant} ${styles.non}`}>
                            <div className={styles.headingWrapperRight}>
                                <div className={styles.heading}>Non Performing</div>
                                <div className={styles.headingImage}><img src={nonperforming.src} alt=''/></div>
                            </div>
                            {q4Content.map((item, index) => (
                              <p className={styles.subText} key={index}>{item}</p>
                            ))}
                            <div className={styles.cardBtnContainer}>
                            <div onClick={(e)=>handleProductView(4)} className={styles.cardPrimaryBtn}>{q4Summary?.summary['total_count']} Products</div>
                            <div onClick={(e)=>handleProductAction("PAUSE")} className={styles.cardPrimaryBtn}>Pause Ads</div>
                            </div>
                            
                        </div>
                    </div>
                    {/* <Scatter
                      height={200}
                      data={chartData}
                      options={chartOptions}
                      plugins={quadrants}
                      width={100}
                      ref={chartRef}
                    /> */}
                    </div>
                    </div>
            </> : fetchIssue ? <PageStatus msg={'Some unknown error occurred!Please Retry.'}/> :<PageStatus msg={'Please select metrics and values to generate quadrant view'}/>
          }
          </div>
    
        </Card.Body>
      </Card>
      {showAlertMsgOf !== "" &&
          <MasterTagetinngActionAlertMModal
            alertText={showAlertMsgOf}
            // alertInfo={"*SB ads not supported at the moment"}
            onYesClick={handleConfirmPause}
            onNoClick={noClickHandler}
            confirmationId={"pause-adset-confirm"}
          ></MasterTagetinngActionAlertMModal>
        }
    </div>
  );
};

export default QuadrantView;
